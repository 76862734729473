<template>
  <b-card-code v-hotkey="keymap">
    <div class="d-flex justify-content-end">
      <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
        >Back</b-button
      >
    </div>
    <b-modal
      ref="new_step_modal"
      hide-footer
      title="New Step"
      centered
      header-close-variant="danger"
      size="lg"
    >
      <validation-observer ref="NewStepAttachmentForm" #default="{ invalid }">
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="handleNewStepCreate"
        >
          <b-form-group label="Step Name" label-for="description">
            <template #label>
              <span>Step Name</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Description"
              vid="description"
              rules="required"
            >
              <b-form-textarea
                name="description"
                v-model="newStep.title"
                :state="errors.length > 0 ? false : null"
                placeholder="A brief description about the evidence"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--Upload File -->
          <div
            class="mt-3 d-flex flex-column align-items-start justify-content-start w-100"
            v-if="newStep.uploaded_file"
          >
            <div
              class="d-flex align-items-center justify-content-between w-100 mb-50"
            >
              <p class="font-weight-bold">Selected file:</p>
              <b-button
                @click="removeNewStepSelectedImage"
                variant="outline-danger"
                size="sm"
                ><feather-icon icon="XIcon" class="mr-50" /><span
                  class="align-middle"
                  >Remove Selected</span
                ></b-button
              >
            </div>
            <!-- <img height="220" width="100%" :ref="newStep.uploaded_file.name"
              :src="convertFileToImage(newStep.uploaded_file)" :alt="newStep.uploaded_file.name" /> -->
            <div
              class="w-100"
              style="overflow: hidden; height: 220px; cursor: grab"
            >
              <panZoom
                :options="{ minZoom: 0.5, maxScale: 5 }"
                selector=".zoomable"
              >
                <div class="zoomable">
                  <img
                    height="100%"
                    width="100%"
                    :ref="newStep.uploaded_file.name"
                    :src="convertFileToImage(newStep.uploaded_file)"
                    :alt="newStep.uploaded_file.name"
                  />
                </div>
              </panZoom>
            </div>
            <p class="mt-25 font-weight-bold">
              {{ newStep.uploaded_file.name }}
            </p>
          </div>
          <b-form-group label="Select Image File" label-for="File">
            <template #label>
              <span>Select Image File</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="ext:png,jpg,jpeg"
            >
              <div class="row">
                <div class="col-6">
                  <b-form-file
                    v-model="newStep.uploaded_file"
                    :state="Boolean(newStep.uploaded_file)"
                    ref="file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </div>
                <div class="col-6">
                  <b-button
                    @click="handlePasteImageToClipboardForNewStep"
                    variant="outline-primary"
                    ><feather-icon icon="CopyIcon" class="mr-50" /><span
                      class="align-middle"
                      >Paste from Clipboard</span
                    ></b-button
                  >
                </div>
              </div>

              <small class="text-danger">{{ errors[0] }}</small>
              <!-- <div class="mt-3">
                Selected file:
                {{
                  attachment_steps_file
                  ? attachment_steps_file.name
                  : ""
                }}
              </div> -->
            </validation-provider>
          </b-form-group>
          <b-form-group label="Caption" label-for="caption">
            <template #label>
              <span>Caption</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Caption"
              vid="caption"
              rules="required"
            >
              <b-form-input
                v-model="newStep.caption"
                :state="errors.length > 0 ? false : null"
                name="caption"
                placeholder="A caption for the evidence"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-button
              type="submit"
              variant="success"
              :disabled="invalid"
              class="my-1"
              size="md"
            >
              <span
                ><feather-icon icon="CheckIcon" class="mr-50" /><span
                  class="align-middle"
                  >Create</span
                ></span
              >
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      ref="edit_step_modal"
      hide-footer
      title="Edit Step"
      centered
      header-close-variant="danger"
      size="lg"
    >
      <validation-observer ref="NewStepAttachmentForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="handleStepUpdate">
          <b-form-group label="Step Name" label-for="description">
            <validation-provider
              #default="{ errors }"
              name="Description"
              vid="description"
              rules="required"
            >
              <b-form-textarea
                name="description"
                v-model="editStep.title"
                :state="errors.length > 0 ? false : null"
                placeholder="A brief description about the evidence"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--Upload File -->
          <div
            class="mt-3 d-flex flex-column align-items-start justify-content-start w-100"
            v-if="editStep.uploaded_file"
          >
            <div
              class="d-flex align-items-center justify-content-between w-100 mb-50"
            >
              <p class="font-weight-bold">Selected file:</p>
              <b-button
                @click="removeEditStepSelectedImage"
                variant="outline-danger"
                size="sm"
                ><feather-icon icon="XIcon" class="mr-50" /><span
                  class="align-middle"
                  >Remove Selected</span
                ></b-button
              >
            </div>

            <div
              class="w-100"
              style="overflow: hidden; height: 220px; cursor: grab"
            >
              <panZoom
                :options="{ minZoom: 0.5, maxScale: 5 }"
                selector=".zoomable"
              >
                <div class="zoomable">
                  <img
                    height="100%"
                    width="100%"
                    :ref="editStep.uploaded_file.name"
                    :src="convertFileToImage(editStep.uploaded_file)"
                    :alt="editStep.uploaded_file.name"
                  />
                </div>
              </panZoom>
            </div>
            <p class="mt-25 font-weight-bold">
              {{ editStep.uploaded_file.name }}
            </p>
          </div>
          <b-form-group label="Select Image File" label-for="File">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="ext:png,jpg,jpeg"
            >
              <div class="row">
                <div class="col-6">
                  <b-form-file
                    v-model="editStep.uploaded_file"
                    :state="Boolean(editStep.uploaded_file)"
                    ref="file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </div>
                <div class="col-6">
                  <b-button
                    @click="handlePasteImageToClipboardForEditStep"
                    variant="outline-primary"
                    ><feather-icon icon="CopyIcon" class="mr-50" /><span
                      class="align-middle"
                      >Paste from Clipboard</span
                    ></b-button
                  >
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
              <!-- <div class="mt-3">
                Selected file:
                {{
                  attachment_steps_file
                  ? attachment_steps_file.name
                  : ""
                }}
              </div> -->
            </validation-provider>
          </b-form-group>
          <b-form-group label="Caption" label-for="caption">
            <validation-provider
              #default="{ errors }"
              name="Caption"
              vid="caption"
              rules="required"
            >
              <b-form-input
                v-model="editStep.caption"
                :state="errors.length > 0 ? false : null"
                name="caption"
                placeholder="A caption for the evidence"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-button
              type="submit"
              variant="success"
              :disabled="invalid"
              class="my-1"
              size="md"
            >
              <span
                ><feather-icon icon="CheckIcon" class="mr-50" /><span
                  class="align-middle"
                  >Update</span
                ></span
              >
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <validation-observer ref="VulnAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addVulnerability">
        <b-form-group label-for="Name">
          <template #label>
            <span>Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
         <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
             <v-select
              taggable
              v-model="form.name"
              label="text"
              :options="vuln_options"
              @search="fetchOptions"
              @option:selected="loadVulnerability"
              @input="onSelect"
              autocomplete
              state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
           <!-- <b-form-input
            id="cvss_score"
            v-model="form.name"
            name="cvss_score"
          />-->
        </b-form-group> 

        <!-- <b-form-group label="CVSS Score" label-for="cvss_score">
          <b-form-input
            id="cvss_score"
            v-model="form.cvss_score"
            name="cvss_score"
          />
        </b-form-group> -->

        <b-form-group label="OWASP Category" label-for="owasp_category">
          <v-select
            v-model="form.owasp_categories"
            multiple
            label="text"
            :options="owasp_options"
            @search="fetchOwaspOptions"
            autocomplete
          />
        </b-form-group>

        <b-form-group label="CVE" label-for="CVE">
          <b-form-textarea v-model="form.cve"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Synopsis" label-for="synopsis">
          <quill-editor v-model="form.synopsis" :options="editorOptionSynopsis">
            <div id="toolbarSynopsis" slot="toolbar">
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
            </div>
          </quill-editor>
          <!-- <b-form-textarea v-model="form.synopsis"></b-form-textarea> -->
        </b-form-group>

        <b-form-group label="Description" label-for="Description">
          <template #label>
            <span>Description</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Description"
            rules="required"
          >
            <quill-editor
              v-model="form.description"
              :options="editorOption"
              :state="errors.length > 0 ? false : null"
            >
              <div id="toolbar" slot="toolbar">
                <button class="ql-bold">Bold</button>
                <button class="ql-italic">Italic</button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-list" value="ordered"></button>
              </div>
            </quill-editor>
            <!-- <b-form-textarea
              v-model="form.description"
              :state="errors.length > 0 ? false : null"
            ></b-form-textarea> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Severity" label-for="Severity">
          <template #label>
            <span>Severity</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="severity"
            rules="required"
          >
            <b-form-select
              v-model="form.severity"
              :options="severity_options"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- <b-form-group label="" label-for="Is Verified?">
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.is_verified"
            name="is_verified"
            value="true"
            unchecked-value="false"
          >
            Is Verified?
          </b-form-checkbox>
        </b-form-group> -->
        <!-- <b-form-group label="" label-for="Is Exploitted?">
          <b-form-checkbox
            id="checkbox-2"
            v-model="form.is_exploited"
            name="is_exploited"
            value="true"
            unchecked-value="false"
          >
            Is Exploitted?
          </b-form-checkbox>
        </b-form-group> -->

        <b-form-group label="Recommendations" label-for="recommendation">
          <quill-editor
            v-model="form.recommendation"
            :options="editorOptionRecommendation"
          >
            <div id="toolbarRecommendation" slot="toolbar">
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
            </div>
          </quill-editor>
          <!-- <b-form-textarea v-model="form.recommendation"></b-form-textarea> -->
        </b-form-group>

        <!-- <b-form-group label="Evidence" label-for="nessus_evidence">
          <b-form-textarea v-model="form.nessus_evidence"></b-form-textarea>
        </b-form-group> -->
        <!-- {{ form.references }}m -->
        <b-form-group label="References" label-for="references">
          <quill-editor
            v-model="form.references"
            :options="editorOptionReferences"
          >
            <div id="toolbarReferences" slot="toolbar">
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
            </div>
          </quill-editor>
          <!-- <b-form-textarea
            id="references"
            v-model="form.references"
            name="references"
          /> -->
        </b-form-group>
        <!-- <div>
          <quill-editor v-model="form.references" :options="editorOptions" />
        </div> -->

        <!-- <div class="d-flex align-items-center justify-content-between">
          <h5>Steps to reproduce</h5>
          <b-button size="sm" variant="success" @click="handleAddNewStepClick"><feather-icon icon="PlusIcon"
              class="mr-50" /><span class="align-middle">New Step</span></b-button>
        </div>
        <div class="w-100 border-secondary rounded my-50">
          <div v-if="steps.length == 0" class="w-auto d-flex flex-column align-items-center justify-content-center"
            style="padding: 3rem 0;">
            <feather-icon icon="DatabaseIcon" class="mb-1 text-secondary" size="32" />
            <h4 class="font-weight-bolder">No Steps</h4>
            <p>Click on the <b>New Step</b> button to add a new step.</p>
          </div>
          <template v-else>
            <draggable :list="steps" tag="ul" class="list-group list-group-flush cursor-move" @change="handleDetectDrag">
              <transition-group type="transition" name="flip-list">
                <b-list-group-item tag="li" class="my-1 mx-1 p-0" v-for="step in steps" :key="step.order_id">
                  <step-to-reproduce-card :step="step" :key="step.title" :gotoDelete="gotoDelete" />
                </b-list-group-item>
              </transition-group>
            </draggable>
          </template>
        </div> -->

        <div class="d-flex align-items-center justify-content-start mt-2">
          <h5 class="mb-0">Asset Affected</h5>
        </div>
        <div
          class="row mx-0"
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col
            lg="12"
            class="border-primary p-1 my-1"
            style="border-radius: 5px"
          >
            <div class="row">
              <div class="col-12 d-flex justify-content-end" v-if="index > 0">
                <a @click="removeItem(index)"
                  ><feather-icon icon="XIcon" class="mr-25"
                /></a>
                <!-- <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                  size="sm"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button> -->
              </div>
              <div class="col-4">
                <b-form-group label="Select Assets" label-for="Assets">
                  <template #label>
                    <span>Select Assets</span>
                    <span
                      class="text-danger font-weight-bold h5"
                      style="margin-left: 3px"
                      >*</span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="assets"
                    rules="required"
                  >
                    <v-select
                      v-model="item.asset_id"
                      label="text"
                      :options="asset_options"
                      @search="fetchAssetOptions"
                      autocomplete
                      state="errors.length > 0 ? false:null"
                      :reduce="(asset_id) => asset_id.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- {{assets}} -->
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group label="Path" label-for="path">
                  <b-form-input id="path" v-model="item.path" name="path" />
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group label="Parameter" label-for="parameter">
                  <b-form-input
                    id="parameter"
                    v-model="item.parameter"
                    name="parameter"
                  />
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group label="Protocol" label-for="Protocol">
                  <b-form-select
                    v-model="item.protocol"
                    :options="protocol_options"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group label="Port" label-for="port">
                  <b-form-input id="port" v-model="item.port" name="port" />
                </b-form-group>
              </div>

              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <b-button
                  v-b-toggle="`collapse-${item.id}`"
                  block
                  variant="primary"
                  size="sm"
                >
                  <b-badge class="mr-75" variant="light"
                    ><span class="text-dark align-middle font-weight-bolder">{{
                      item.steps_to_reproduce.length
                    }}</span></b-badge
                  ><span class="align-middle">Steps To Reproduce</span
                  ><feather-icon
                    size="20"
                    :icon="
                      collapsedRows.includes(item.id)
                        ? 'ChevronUpIcon'
                        : 'ChevronDownIcon'
                    "
                    class="ml-1"
                /></b-button>
              </div>

              <div class="col-12">
                <div
                  class="w-100 d-flex flex-column align-items-start justify-content-start"
                >
                  <b-collapse
                    :id="`collapse-${item.id}`"
                    @show="handleCollapseShown(item.id)"
                    @hide="handleCollapseHidden(item.id)"
                    class="mt-2 w-100"
                  >
                    <div
                      class="w-100 d-flex align-items-center justify-content-between"
                    >
                      <h5>Steps to reproduce</h5>
                      <b-button
                        size="sm"
                        variant="success"
                        @click="handleAddNewStepClick(item.id)"
                        ><feather-icon icon="PlusIcon" class="mr-50" /><span
                          class="align-middle"
                          >New Step</span
                        ></b-button
                      >
                    </div>
                    <div class="w-100 bg-light rounded my-50 px-1">
                      <div
                        v-if="item.steps_to_reproduce.length == 0"
                        class="w-auto d-flex flex-column align-items-center justify-content-center"
                        style="padding: 3rem 0"
                      >
                        <feather-icon
                          icon="DatabaseIcon"
                          class="mb-1 text-secondary"
                          size="32"
                        />
                        <h4 class="font-weight-bolder">No Steps</h4>
                        <p>
                          To create a new step click on the
                          <b>New Step</b> button on the top right side of this
                          section.
                        </p>
                      </div>
                      <template v-else>
                        <draggable
                          :list="item.steps_to_reproduce"
                          tag="ul"
                          class="list-group list-group-flush cursor-move"
                          @change="handleDetectDrag(item)"
                        >
                          <transition-group type="transition" name="flip-list">
                            <b-list-group-item
                              tag="li"
                              class="my-1 mx-1 p-0"
                              v-for="step in item.steps_to_reproduce"
                              :key="step.order_id"
                            >
                              <step-to-reproduce-card
                                :step="step"
                                :key="step.title"
                                :gotoDelete="
                                  (e) => {
                                    gotoDelete(item.id, step.order_id);
                                  }
                                "
                                :editSteps="
                                  (e) => {
                                    editSteps({ ...item }, step.order_id);
                                  }
                                "
                              />
                            </b-list-group-item>
                          </transition-group>
                        </draggable>
                      </template>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </b-col>

          <!-- <b-col lg="1" md="1" class="mb-50" v-if="index > 0">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
              size="sm"
            >
              <feather-icon icon="XIcon" class="mr-25" />
            </b-button>
          </b-col> -->
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <b-button
              @click="repeateAgain"
              variant="outline-success"
              size="sm"
              :disabled="isNewAssetButtonDisabled"
            >
              <feather-icon icon="PlusIcon" class="mr-50" /> New Asset
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              :disabled="invalid || disable"
              size="sm"
            >
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>Add</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BModal,
  BCollapse,
  BListGroupItem,
  VBToggle,
  BBadge,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import StepToReproduceCard from "./components/StepToReproduceCard.vue";
import draggable from "vuedraggable";
import ResponseMixins from "@/mixins/ResponseMixins";
import VulnerabilityMixins from "@/mixins/VulnerabilityMixins";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  mixins: [ResponseMixins, VulnerabilityMixins],
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
    BModal,
    StepToReproduceCard,
    draggable,
    BListGroupItem,
    BCollapse,
    BBadge,
    quillEditor,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      editorOptionCVE: {
        modules: {
          toolbar: "#toolbarCVE",
        },
      },
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      editorOptionSynopsis: {
        modules: {
          toolbar: "#toolbarSynopsis",
        },
      },
      editorOptionRecommendation: {
        modules: {
          toolbar: "#toolbarRecommendation",
        },
      },
      editorOptionReferences: {
        modules: {
          toolbar: "#toolbarReferences",
        },
      },
      selectedItemId: null,
      collapsedRows: [],
      steps: [],
      newStep: {
        title: null,
        caption: null,
        uploaded_file: null,
        order_id: 1,
      },
      editStep: {
        title: null,
        caption: null,
        uploaded_file: null,
        order_id: 1,
      },
      selectedStep: null,
      steps_files: [],
      is_staff: this.$store.state.app.user.is_staff,
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      protocol_options: [
        { value: "-", text: "-" },
        { value: "tcp", text: "TCP" },
        { value: "udp", text: "UDP" },
      ],
      vuln_options: [],
      owasp_options: [],
      asset_options: [],
      org_id: this.$store.state.app.org_id,
      // org_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      path_or_port: "",
      form: {
        name: "",
        // vm_name: "",
        // cvss_score: "",
        owasp_categories: "",
        cve: "",
        severity: null,
        description: "",
        synopsis: "",
        recommendation: "",
        references: "",
        // is_exploited: false,
        // is_verified: false,
        // nessus_evidence: "",
        //origin: "CUSTOM",
        // asset_id: this.$route.params.id,
        // vuln_id: "",
        asset_affected: [],
        content: "",
        editorOptions: {
          modules: {
            toolbar: [
              ["italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
            ],
          },
        },
      },

      items: [
        {
          id: 1,
          asset_id: "",
          path: "-",
          port: "-",
          parameter: "",
          protocol: "-",
          steps_to_reproduce: [],
        },
      ],
      nextItemId: 1,
      isloading: false,
      disable: false,
    };
  },
  created: function () {
    //  this.load(); --> not calling this due page load issues
    this.fetchAssetOptions();
  },
  computed: {
    keymap() {
      return {
        "ctrl+v": this.handlePasteKeyPress,
      };
    },

    isNewAssetButtonDisabled() {
      // Check if any item.asset_id is empty
      return this.items.some((item) => !item.asset_id);
    },
  },
  methods: {
    load: function () {},
    onSelect(value) {
      if (value) {
        this.form.name = value.text; // Set the v-model to the value
      } else {
        this.form.name = ""; // Clear the v-model if the close button is clicked
      }
    },

    async handlePasteImageToClipboardForNewStep() {
      let step = this.newStep;
      step.uploaded_file = await this.getImageFromClipboard();
      this.newStep = step;
    },

    async handlePasteImageToClipboardForEditStep() {
      let step = this.editStep;
      step.uploaded_file = await this.getImageFromClipboard();
      this.editStep = step;
    },

    async getImageFromClipboard() {
      const auth = await navigator.permissions.query({
        name: "clipboard-read",
      });
      if (auth.state !== "denied") {
        const item_list = await navigator.clipboard.read();
        let image_type; // we will feed this later
        const item = item_list.find(
          (
            item // choose the one item holding our image
          ) =>
            item.types.some((type) => {
              // does this item have our type
              if (type.startsWith("image/")) {
                image_type = type; // store which kind of image type it is
                return true;
              }
            })
        );
        const file = item && (await item.getType(image_type));
        if (file && file !== null && typeof file != "undefined") {
          return this.blobToFile(file, "Screenshot.png");
        }
        console.log("File", file);
      }
    },

    handlePasteKeyPress() {
      this.pasteImage();
    },

    removeNewStepSelectedImage() {
      this.$set(this.newStep, "uploaded_file", null);
    },
    removeEditStepSelectedImage() {
      this.$set(this.editStep, "uploaded_file", null);
    },

    handleCollapseShown(id) {
      if (!this.collapsedRows.includes(id)) {
        this.collapsedRows.push(id);
      }
    },

    handleCollapseHidden(id) {
      if (this.collapsedRows.includes(id)) {
        this.collapsedRows = this.collapsedRows.filter((sid) => sid != id);
      }
    },

    handleNewStepCreate() {
      let step = this.newStep;
      let newItems = this.items;
      const itemIndex = newItems.findIndex(
        (item) => item.id == this.selectedItemId
      );
      step.order_id = newItems[itemIndex].steps_to_reproduce.length + 1;
      newItems[itemIndex].steps_to_reproduce.push(step);
      this.items = newItems;

      //Clearing the NewStep state
      this.newStep = {
        title: null,
        caption: null,
        uploaded_file: null,
        order_id: 1,
      };
      this.hideNewStepModal();
    },

    handleDetectDrag: function (step) {
      let newItems = this.items;
      const itemIndex = newItems.findIndex(
        (item) => item.id == this.nextItemId
      );
      newItems[itemIndex].steps_to_reproduce.map((step, i) => {
        step.order_id = i + 1;
        return step;
      });
      this.items = newItems;

      // const newData = this.steps.map((step, i) => {
      //   step.order_id = i + 1
      //   return step
      // })
      // this.steps = newData
    },

    blobToFile(theBlob, fileName) {
      const myFile = new File([theBlob], fileName, {
        type: theBlob.type,
      });
      return myFile;
    },

    async pasteImage() {
      const auth = await navigator.permissions.query({
        name: "clipboard-read",
      });
      if (auth.state !== "denied") {
        const item_list = await navigator.clipboard.read();
        let image_type; // we will feed this later
        const item = item_list.find(
          (
            item // choose the one item holding our image
          ) =>
            item.types.some((type) => {
              // does this item have our type
              if (type.startsWith("image/")) {
                image_type = type; // store which kind of image type it is
                return true;
              }
            })
        );
        const file = item && (await item.getType(image_type));
        if (file && file !== null && typeof file != "undefined") {
          this.newStep.uploaded_file = this.blobToFile(file, "Screenshot.png");
          this.editStep.uploaded_file = this.blobToFile(file, "Screenshot.png");
        }
      }
    },

    // downloadAttachment(filename) {
    //     var url;
    //     url =
    //         process.env.VUE_APP_BASEURL +
    //         "asset/vulns/" +
    //         this.$route.params.id +
    //         "/download-steps-to-reproduce-evidences/";
    //     const options = {
    //       method: "POST",
    //       data: { filename: filename },
    //       url: url,
    //       responseType: "blob",
    //     };
    //     this.$http(options).then((res) => {
    //       const url = window.URL.createObjectURL(new Blob([res.data]));
    //       this.$set(this.steps_files, filename, url);
    //     });
    //   },

    handleStepUpdate() {
      const itemIndex = this.items.findIndex(
        (item) => item.id == this.selectedItemId
      );
      let newItem = this.items[itemIndex];
      const stepIndex = this.items[itemIndex].steps_to_reproduce.findIndex(
        (step) => step.order_id == this.editStep.order_id
      );
      // let updatedItem = newItems[itemIndex].steps_to_reproduce[stepIndex] = this.editStep;
      newItem.steps_to_reproduce[stepIndex] = this.editStep;
      this.$set(this.items, itemIndex, newItem);
      console.log("Items", this.items);
      this.hideEditStepModal();
      this.editStep = {
        title: null,
        caption: null,
        order_id: null,
        uploaded_file: null,
      };
    },

    showEditStepModal() {
      this.$refs["edit_step_modal"].show();
    },
    hideEditStepModal() {
      this.$refs["edit_step_modal"].hide();
    },

    editSteps(item, order_id) {
      const stepIndex = item.steps_to_reproduce.findIndex(
        (step) => step.order_id == order_id
      );
      const newEditStep = {
        title: item.steps_to_reproduce[stepIndex].title,
        caption: item.steps_to_reproduce[stepIndex].caption,
        order_id: item.steps_to_reproduce[stepIndex].order_id,
        uploaded_file: item.steps_to_reproduce[stepIndex].uploaded_file,
      };
      this.editStep = newEditStep;
      this.selectedItemId = item.id;
      this.showEditStepModal();
    },
    gotoDelete(itemId, order_id) {
      let newItems = this.items;
      const itemIndex = newItems.findIndex((item) => item.id == itemId);
      let newSteps = newItems[itemIndex].steps_to_reproduce.filter(
        (step) => step.order_id != order_id
      );
      newItems[itemIndex].steps_to_reproduce = newSteps;
      newItems[itemIndex].steps_to_reproduce.map((step, i) => {
        step.order_id = i + 1;
        return step;
      });
      this.items = newItems;
      // let newSteps = this.steps.filter(step => step.order_id != order_id)
      // this.steps = newSteps
    },

    convertFileToImage(file) {
      if (file) {
        return URL.createObjectURL(file);
      }
    },

    showNewStepModal() {
      this.$refs["new_step_modal"].show();
    },
    hideNewStepModal() {
      this.$refs["new_step_modal"].hide();
    },

    handleAddNewStepClick(id) {
      this.selectedItemId = id;
      this.showNewStepModal();
    },

    uploadFilesAndGetNewResponse(items) {
      return new Promise((resolve, reject) => {
        let uploadPromises = [];
        let newItems = [];
        items.map((item) => {
          item.steps_to_reproduce.map((step) => {
            let uploadPromise = new Promise((reso, rej) => {
              if (step.uploaded_file && step.uploaded_file != null) {
                this.uploadFile(step.uploaded_file)
                  .then((res) => {
                    reso();
                    step.uploaded_file = res.data;
                    return step;
                  })
                  .catch((err) => {
                    rej(err);
                  });
              } else {
                reso();
              }
            });
            uploadPromises.push(uploadPromise);
          });
          newItems.push(item);
        });
        Promise.all(uploadPromises)
          .then(() => {
            resolve(newItems);
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
            reject(err);
          });
      });
    },

    addVulnerability: function () {
      this.$refs.VulnAddForm.validate().then((success) => {
        this.disable = true;
        this.isloading = true;
        if (success) {
          let i = 0;
          var self = this;
          this.items.forEach(function (item, index) {
            i = i + 1;
            self.items[index].id = i;
          });

          // let newItems = this.items.map(item => {
          //   item.steps_to_reproduce.map(step => {
          //     this.uploadFile(step.uploaded_file)
          //       .then(res => {
          //         console.log('Uploaded file', res.data)
          //         step.uploaded_file = res.data
          //         console.log('Step', step)
          //         return step
          //       })
          //       .catch(err => {
          //         console.log(err)
          //         this.handleError(err)
          //       })
          //   })
          //   return item
          // })

          this.uploadFilesAndGetNewResponse(this.items)
            .then((res) => {
              console.log("res", res);
              let newForm = { ...this.form };
              newForm.asset_affected = res;
              console.log("Form", newForm);
              const options = {
                method: "POST",
                data: newForm,
                url:
                  process.env.VUE_APP_BASEURL +
                  "asset/vulns/add-vuln-to-multiple-assets/",
              };
              this.$http(options).then((res) => {
                console.log("Add Vulnerability", res.data);
                if (res.data.status == "success") {
                  this.$router.push({
                    name: "All Vulnerabilities",
                  });
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Successfully added vulnerability! ",
                      icon: "EditIcon",
                      variant: "success",
                    },
                  });
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.message,
                      icon: "EditIcon",
                      variant: "danger",
                    },
                  });
                }
                this.disable = true;
                this.isloading = false;
              });
            })
            .catch((err) => {
              this.handleError(err);
            });
        }
      });
    },

    loadVulnerability(selectedOption) {
      console.log(selectedOption);
      var self = this;
      this.vuln_options.map(function (value, key) {
        console.log("Vl", value.text);
        if (value.text == selectedOption.value) {
          // self.form = value.full_details
          self.form.name = value.full_details.name;
          self.form.vm_name = value.full_details.vm_name;
          self.form.cvss_score = value.full_details.cvss_score;
          self.form.owasp_categories = value.full_details.owasp_categories;
          self.form.severity = value.full_details.severity;
          self.form.description = value.full_details.description;
          self.form.synopsis = value.full_details.synopsis;
          self.form.recommendation = value.full_details.recommendation;
          self.form.references = value.full_details.references;
          self.form.vuln_id = value.full_details.id;
          self.form.cve = value.full_details.cve;
          self.form.port = value.full_details.port;
          self.form.path = value.full_details.path;
          self.form.protocol = value.full_details.protocol;
        }
      });
    },
    // ------new asset listing without search

    fetchAssetOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.org_id +
            "&name=" +
            search,
        };
        var self = this;

        this.$http(a_options).then((res) => {
          self.asset_options = [];
          // self.asset_filter_options.push({ value: null, text: "--Searching for "+search });
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_options.push(a);
          });
        });
      }
    },

    // -------previous asset listing

    // fetchAssetOptions(search, loading) {
    //   if (search.length) {
    //     loading(true);
    //     // Load assets here
    //     const a_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "asset/org-asset/list-all-org-assets?org_id=" +
    //         this.org_id +
    //         "&name=" +
    //         search,
    //     };
    //     var self = this;
    //     this.$http(a_options).then((res) => {
    //       self.asset_options = [];
    //       res.data.map(function (value, key) {
    //         let a = {
    //           value: res.data[key].asset_id,
    //           text:
    //             res.data[key].asset_nickname +
    //             " (" +
    //             res.data[key].assetType.asset_type_name +
    //             ")",
    //         };
    //         self.asset_options.push(a);
    //         loading(false);
    //       });
    //     });
    //   }
    // },
    fetchOptions(search, loading) {
      if (search.length) {
        // loading(true);
        // Load Users here
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/cvd-search?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          // self.vuln_options = [];
          // res.data.results.map(function (value, key) {
          //   let a = {
          //     value: value.name,
          //     text: value.name,
          //     full_details: value,
          //   };
          //   self.vuln_options.push(a);
          // });
          const newOptions = res.data.results.map(function (value) {
            return {
              value: value.name,
              text: value.name,
              full_details: value,
            };
          });

          // Check if an option already exists in vuln_options
          const uniqueOptions = newOptions.filter((newOption) => {
            return !self.vuln_options.some((existingOption) => {
              return existingOption.value === newOption.value;
            });
          });
          // Add unique options to vuln_options
          self.vuln_options = self.vuln_options.concat(uniqueOptions);
        });
      }
    },
    fetchOwaspOptions(search, loading) {
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/owasp-search?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.owasp_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: value.title,
              text: value.title,
              // full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.owasp_options.push(a);
          });
        });
      }
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextItemId = this.nextItemId + 1),
        asset_id: "",
        path: "-",
        port: "-",
        parameter: "",
        protocol: "-",
        steps_to_reproduce: [],
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      //   this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.b-form-textarea {
  resize: vertical !important;
}

[dir] .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: rgba(245, 231, 231, 0);
  border-radius: 5px !important;
  border: 2px solid rgb(45 95 138 / 13%);
}

[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  border-radius: 5px !important;
  background-color: #05050500 !important;
  border: 2px solid rgb(45 95 138 / 13%);
}

.list-group-item {
  transition: all 0.6s;
}
</style>