export default {
  methods: {


    uploadFile(file) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("uploaded_file", file);
        const options = {
          method: "POST",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/vulns/add-multiple-steps-to-reproduce-evidences/",
          data: formData,
        };

        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
